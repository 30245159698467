import React from "react";
import { ZendeskAPI } from "react-zendesk";
import styled from "styled-components";

import Icon from "components/atomic/atoms/Icon";

const StyledRoundMenu = styled.div`
  z-index: 20;
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 991px) {
    gap: 8px;
    bottom: 30px;
    right: 16px;
  }
`;

const RoundWrapper = styled.div`
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ color }) => color};
  border-radius: 100px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);

  cursor: pointer;

  @media (max-width: 991px) {
    height: 48px;
    width: 48px;
  }
`;

const RoundMenu = () => {
  const handleChat = () => {
    ZendeskAPI("webWidget", "setLocale", "en");
    ZendeskAPI("webWidget", "show");
    ZendeskAPI("webWidget", "open");

    ZendeskAPI("webWidget:on", "close", function () {
      ZendeskAPI("webWidget", "hide");
    });
  };

  return (
    <StyledRoundMenu>
      <RoundWrapper onClick={handleChat} color="#444ce7">
        <Icon icon="misc:message" color="#ffffff" />
      </RoundWrapper>
    </StyledRoundMenu>
  );
};

export default RoundMenu;
