import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { AMENDMENT_PRODUCTS } from "_constants/products";
import { handleError, phoneCleaner, validateTrim } from "_helpers";
import { getStatesOptions } from "_helpers/variables";
import { OrdersService } from "_services";
import { selectActiveOrderId, selectRAProduct } from "_store/orders/selector";
import { getOrder } from "_store/orders/slice";
import { selectCustomerId } from "_store/user/selector";

import Icon from "components/atomic/atoms/Icon";
import { SpinnerBlock } from "components/atomic/atoms/Spinner";
import FileField from "components/atomic/molecules/fields/FileField";
import PhoneField from "components/atomic/molecules/fields/PhoneField";
import SelectField from "components/atomic/molecules/fields/SelectField";
import SwitchField from "components/atomic/molecules/fields/SwitchField";
import TextField from "components/atomic/molecules/fields/TextField";

const StyledHeader = styled(Modal.Header)`
  font-size: 1rem;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-light"]};
  `}
`;

const StyledBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;

  width: 100%;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-dark"]};
  `}

  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}

    ${({ centerContent }) =>
    centerContent &&
    css`
      justify-content: center;
    `}
`;

const Col = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;

  ${({ smallGap }) =>
    smallGap &&
    css`
      gap: 8px;
    `}

  ${({ $flex }) =>
    $flex &&
    css`
      flex: ${$flex};
    `}
`;

const Buttons = styled.div`
  display: flex;
  justify-content: end;
  gap: 12px;
`;

const ErrorText = styled.div`
  font-weight: bold;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-danger"]};
  `}
`;

const ErrorMessage = styled.div`
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 0 4px gray inset;
  font-family: monospace;
  word-wrap: break-word;
`;

const StyledIcon = styled(Icon)`
  display: inline;
`;

const STEPS = Object.freeze({
  init: "",
  start: "stepStart",
  rerunSuccess: "stepRerunSuccess",
  uploadAmendment: "stepUploadAmendment",
  fileAmendment: "stepFileAmendment",
  loading: "stepLoading",
  error: "stepError",
  fileSuccess: "stepFileSuccess",
  uploadSuccess: "stepUploadSuccess",
});

const rules = {
  firstName: () => ({
    required: "First Name is a required field",
    // maxLength: {
    //   value: 30,
    //   message: "First Name should be less or equal 30 symbols",
    // },
    // pattern: {
    //   value: /^[ a-zA-Z\-&]{1,30}$/i,
    //   message:
    //     "There shouldn't be numbers here, the only punctuation and special characters allowed are hyphen (-), backslash (\\) and ampersand (&)",
    // },
    validate: validateTrim,
  }),
  lastName: () => ({
    required: "Last Name is a required field",
    // maxLength: {
    //   value: 30,
    //   message: "Last Name should be less or equal 30 symbols",
    // },
    // pattern: {
    //   value: /^[ a-zA-Z\-&]{1,30}$/i,
    //   message:
    //     "There shouldn't be numbers here, the only punctuation and special characters allowed are hyphen (-), backslash (\\) and ampersand (&)",
    // },
    validate: validateTrim,
  }),
  phone: () => ({
    required: "Phone is a required field",
    validate: (value) => {
      if (value?.includes("_")) {
        return "You must provide a valid phone number";
      }

      return true;
    },
  }),
  email: () => ({
    required: "Email is a required field",
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "Invalid Email address",
    },
    maxLength: {
      value: 100,
      message: "Email should be less or equal 100 symbols",
    },
  }),

  companyName: () => ({
    required: "Entity Name is a required field",
    validate: validateTrim,
  }),

  address1: () => ({
    required: "Address is a required field",
    validate: validateTrim,
  }),

  address2: () => ({
    // required: "Apt/Suite is a required field",
    validate: validateTrim,
  }),

  state: () => ({
    required: "State is a required field",
    validate: validateTrim,
  }),

  city: () => ({
    required: "City is a required field",
    // pattern: {
    //   value: /^[ a-zA-Z\-&]{1,30}$/i,
    //   message:
    //     "There shouldn't be numbers here, the only punctuation and special characters allowed are hyphen (-), backslash (\\) and ampersand (&)",
    // },
    validate: validateTrim,
  }),

  zip: () => ({
    required: "ZIP is a required field",
    maxLength: {
      value: 5,
      message: "ZIP should be equal 5 digits",
    },
    pattern: {
      value: /^\d{5}$/i,
      message: "Provide ZIP in the required format",
    },
    validate: validateTrim,
  }),

  proofFile: () => ({
    validate: (value) => {
      if (!value) {
        return "Please, provide file";
      }

      if (value?.size && value?.size / 1024 / 1024 > 5) {
        return "File size limit is 5MB";
      }

      return true;
    },
  }),
};

const BlockedRAModal = ({ action, actions, onCancel }) => {
  const dispatch = useDispatch();
  const formProofFile = useForm({ defaultValues: { proofFile: null } });
  const formContactInfo = useForm({
    defaultValues: {
      isIndividual: true,
      firstName: "",
      lastName: "",
      companyName: "",
      phone: "",
      email: "",
      address1: "",
      address2: "",
      state: "",
      city: "",
      zip: "",
    },
  });

  const valuesContactInfo = useWatch({ control: formContactInfo?.control });

  const orderId = useSelector(selectActiveOrderId);
  const customerId = useSelector(selectCustomerId);
  const productDetailsRA = useSelector(selectRAProduct);

  const [error, setError] = useState(null);
  const [step, setStep] = useState(STEPS.start);

  function handleCloseModal({ update = false }) {
    setError(null);
    setStep(STEPS.init);
    onCancel();

    update && dispatch(getOrder({ orderId, customerId, refreshOrders: true }));
  }

  async function rerunRaSubscription() {
    try {
      setStep(STEPS.loading);

      const response = await OrdersService.rerunSubscription({
        orderId,
        productId: productDetailsRA?.id,
      });

      if (response?.status >= 200 && response?.status < 300) {
        setStep(STEPS.rerunSuccess);
      } else {
        setStep(STEPS.error);
        setError({ message: "Unexpected issue with payment" });
      }
    } catch (error) {
      setStep(STEPS.error);
      setError(handleError(error));
    }
  }

  async function uploadFile(form) {
    try {
      setStep(STEPS.loading);

      const formData = new FormData();
      formData.append("file", form.proofFile);

      const response = await OrdersService.uploadFile({
        orderId,
        productId: productDetailsRA?.id,
        body: formData,
        fileType: "AMENDMENT_PROOF",
      });

      if (response?.status === 200) {
        setStep(STEPS.uploadSuccess);
      } else {
        setStep(STEPS.error);
        setError({ message: "Unexpected issue with file uploading" });
      }
    } catch (error) {
      setStep(STEPS.error);
      setError(handleError(error));
    }
  }

  async function createAmendmentUpsale(form) {
    try {
      setStep(STEPS.loading);

      const body = {
        order: { processingOption: "UPSELL", campaignId: 29 },
        products: [
          {
            code: { code: AMENDMENT_PRODUCTS.incAmendmentRegisteredAgent },
            companyName: !form?.isIndividual && form?.companyName ? form?.companyName : null,
            firstName: form?.firstName ? form?.firstName : null,
            lastName: form?.isIndividual && form?.lastName ? form?.lastName : null,
            email: form?.email || null,
            phone: phoneCleaner(form?.phone) || null,
            raAddress: {
              address1: form?.address1 || null,
              address2: form?.address2 || null,
              state: form?.state || null,
              city: form?.city || null,
              zip: form?.zip || null,
              country: "US",
            },
          },
        ],
      };

      const request = await OrdersService.createDelayedUpsell({ orderId, body });

      if (request?.status === 200) {
        if (request?.data?.products[0]?.paymentStatus === "Declined") {
          setError({
            message: "Uh oh - your card on file has been declined. Please update your payment method to proceed.",
          });
          setStep(STEPS.error);
        } else {
          setStep(STEPS.fileSuccess);
        }
      } else {
        setStep(STEPS.error);
        setError({ message: "Unknown Error" });
      }
    } catch (response) {
      setStep(STEPS.error);
      setError(handleError(response));
    }
  }

  useEffect(() => {
    if (orderId) {
      if (action === actions.submitPayment) {
        rerunRaSubscription();
      }

      if (action === actions.uploadAmendment) {
        setStep(STEPS.uploadAmendment);
      }

      if (action === actions.fileAmendment) {
        setStep(STEPS.fileAmendment);
      }
    }

    // orderId && setStep(STEPS.start);
  }, [orderId, action]);

  switch (step) {
    case STEPS.uploadAmendment:
      return (
        <Modal centered show onHide={() => handleCloseModal({ update: false })} size="md" backdrop="static">
          <form onSubmit={formProofFile.handleSubmit(uploadFile)}>
            <StyledHeader closeButton>Registered Agent Subscription</StyledHeader>
            <StyledBody>
              <Row>Please upload your Registered Agent Amendment Filing Confirmation</Row>
              <Row>
                <FileField
                  column
                  // label="File"
                  name="proofFile"
                  control={formProofFile.control}
                  errors={formProofFile.formState.errors}
                  rules={rules.proofFile()}
                />
              </Row>
            </StyledBody>
            <Modal.Footer>
              <Button variant="success" type="submit">
                Upload
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      );

    case STEPS.fileAmendment:
      return (
        <Modal centered show onHide={() => handleCloseModal({ update: false })} size="md" backdrop="static">
          <form onSubmit={formContactInfo.handleSubmit(createAmendmentUpsale)}>
            <StyledHeader closeButton>Registered Agent Subscription</StyledHeader>
            <StyledBody>
              <Row>Please provide the contact information of the new Registered Agent for the amendment:</Row>
              <Row>
                <SwitchField
                  column
                  name="isIndividual"
                  labelOn="Individual"
                  labelOff="Organization"
                  control={formContactInfo.control}
                  errors={formContactInfo.formState.errors}
                />
              </Row>
              {valuesContactInfo?.isIndividual && (
                <Row>
                  <Col>
                    <TextField
                      column
                      name="firstName"
                      placeholder="First Name"
                      control={formContactInfo.control}
                      errors={formContactInfo.formState.errors}
                      rules={rules.firstName()}
                    />
                  </Col>
                  <Col>
                    <TextField
                      column
                      name="lastName"
                      placeholder="Last Name"
                      control={formContactInfo.control}
                      errors={formContactInfo.formState.errors}
                      rules={rules.lastName()}
                    />
                  </Col>
                </Row>
              )}
              {!valuesContactInfo?.isIndividual && (
                <Row>
                  <Col>
                    <TextField
                      column
                      name="companyName"
                      placeholder="Entity Name"
                      control={formContactInfo.control}
                      errors={formContactInfo.formState.errors}
                      rules={rules.companyName()}
                    />
                  </Col>
                </Row>
              )}

              <Row>
                <Col $flex="7">
                  <TextField
                    column
                    name="address1"
                    label="Address"
                    placeholder="Address"
                    control={formContactInfo.control}
                    errors={formContactInfo.formState.errors}
                    rules={rules.address1()}
                  />
                </Col>
                <Col $flex="5">
                  <TextField
                    column
                    name="address2"
                    label="Apt or Suite"
                    placeholder="Apt or Suite"
                    control={formContactInfo.control}
                    errors={formContactInfo.formState.errors}
                    rules={rules.address2()}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <TextField
                    column
                    name="city"
                    label="City"
                    placeholder="City"
                    control={formContactInfo.control}
                    errors={formContactInfo.formState.errors}
                    rules={rules.city()}
                  />
                </Col>
                <Col>
                  <SelectField
                    column
                    name="state"
                    label="State"
                    placeholder="State"
                    options={getStatesOptions(true)}
                    control={formContactInfo.control}
                    errors={formContactInfo.formState.errors}
                    rules={rules.state()}
                  />
                </Col>
                <Col>
                  <TextField
                    column
                    name="zip"
                    label="ZIP"
                    placeholder="12345"
                    maxLength={5}
                    control={formContactInfo.control}
                    errors={formContactInfo.formState.errors}
                    rules={rules.zip()}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <PhoneField
                    column
                    label="Phone"
                    placeholder="Phone"
                    name="phone"
                    control={formContactInfo.control}
                    errors={formContactInfo.formState.errors}
                    rules={rules.phone()}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <TextField
                    column
                    autocomplete="email"
                    label="Email"
                    placeholder="Email"
                    name="email"
                    control={formContactInfo.control}
                    errors={formContactInfo.formState.errors}
                    rules={rules.email()}
                  />
                </Col>
              </Row>
            </StyledBody>
            <Modal.Footer>
              <Row>
                Please note that amendment requests incur a $99.00 processing fee (inclusive of State fees). After
                clicking Process below, your card on file will be billed for this amount. An agent will follow up with
                you directly to complete your request.
              </Row>

              <Buttons>
                <Button variant="secondary" onClick={() => handleCloseModal({ update: false })}>
                  Cancel
                </Button>
                <Button variant="success" type="submit">
                  Process
                </Button>
              </Buttons>
            </Modal.Footer>
          </form>
        </Modal>
      );

    case STEPS.fileSuccess:
      return (
        <Modal centered show onHide={() => handleCloseModal({ update: true })} size="md">
          <StyledHeader>Registered Agent Subscription</StyledHeader>
          <StyledBody>
            <Row>
              <div>
                <div style={{ marginBottom: "1rem" }}>
                  <Icon inline icon="misc:success-check" size="32px" color="#32D583" />
                  &nbsp;Thank you!
                </div>
                <p>Your Registered Agent Amendment order has successfully been placed.</p>
                <p>
                  The block will be removed from your Membership within 1-2 minutes (you will need to refresh the page).
                </p>
              </div>
            </Row>
          </StyledBody>
          <Modal.Footer>
            <Button variant="success" onClick={() => handleCloseModal({ update: true })}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      );

    case STEPS.uploadSuccess:
      return (
        <Modal centered show onHide={() => handleCloseModal({ update: true })} size="md">
          <StyledHeader>Registered Agent Subscription</StyledHeader>
          <StyledBody>
            <Row>
              <div>
                <div style={{ marginBottom: "1rem" }}>
                  <Icon inline icon="misc:success-check" size="32px" color="#32D583" />
                  &nbsp;Thank you for your file!
                </div>
                <p>
                  Our specialists will review to make sure that the amendment was successfully filed and remove the
                  block from your Membership.
                </p>
              </div>
            </Row>
          </StyledBody>
          <Modal.Footer>
            <Button variant="success" onClick={() => handleCloseModal({ update: true })}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      );

    case STEPS.rerunSuccess:
      return (
        <Modal centered show onHide={() => handleCloseModal({ update: true })} size="md">
          <StyledHeader>Registered Agent Subscription</StyledHeader>
          <StyledBody>
            <Row>Thank you for your payment! The block will be removed immediately.</Row>
          </StyledBody>
          <Modal.Footer>
            <Button variant="success" onClick={() => handleCloseModal({ update: true })}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      );

    case STEPS.loading:
      return (
        <Modal centered show backdrop="static" onHide={() => {}} size="md">
          <StyledHeader>Registered Agent Subscription</StyledHeader>
          <StyledBody>
            <SpinnerBlock message="Sending Request" />
          </StyledBody>
        </Modal>
      );

    case STEPS.error:
      return (
        <Modal centered show onHide={() => handleCloseModal({ update: true })} size="md">
          <StyledHeader closeButton>Registered Agent Subscription</StyledHeader>
          <StyledBody>
            <Row>
              <Col smallGap>
                <ErrorText>
                  <StyledIcon icon="error:alert" size="24px" />
                  &nbsp;Error:
                </ErrorText>
                <ErrorMessage>{error?.message || "Something went wrong..."}</ErrorMessage>
              </Col>
            </Row>
          </StyledBody>
          <Modal.Footer>
            <Button variant="success" onClick={() => handleCloseModal({ update: true })}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      );

    default:
      return null;
  }
};

BlockedRAModal.propTypes = {
  action: PropTypes.string,
  actions: PropTypes.object,
  onCancel: PropTypes.func,
};

export default BlockedRAModal;
