import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import styled from "styled-components";

import { UPDATE_REQUIRED_CODES } from "_constants/variables";
import { selectIsUpdateRequired } from "_store/application/selector";
// import { selectInitialLoading } from "_store/application/selector";
import { checkRequiredProducts } from "_store/application/slice";
import {
  selectActiveOrderId,
  selectActiveOrderLoading,
  selectFileLoading,
  selectLoading,
  selectMembershipBlocked,
  selectOrdersLoading,
} from "_store/orders/selector";
import { getOrder, getOrders, getProcessingErrors, getProcessingStatuses, getStates } from "_store/orders/slice";
import { selectCustomerId, selectDetails } from "_store/user/selector";

import MembershipBlockedAlert from "components/atomic/atoms/MembershipBlockedAlert";
import RoundMenu from "components/atomic/atoms/RoundMenu";
import { Spinner, SpinnerWrapper } from "components/atomic/atoms/Spinner";
import Header from "components/atomic/organisms/Header";
import Sidebar from "components/atomic/organisms/Sidebar";
import { footeritems, navitems } from "components/atomic/organisms/Sidebar/menuItems";
import UpdateRequiredModal from "components/atomic/organisms/modals/UpdateRequiredModal";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 260px);
  height: 100%;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

const MainContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 48px 42px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors["cl-content-bg"]};

  @media (max-width: 991px) {
    padding: 12px;
    /* margin-bottom: 48px; */
  }
`;

const FileLoading = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 4px 8px;
  background: #98a2b3;
  border: 1px solid lightgray;
  border-radius: 0 12px 0 0;
`;

const PageLayout = ({ handler, ...props }) => {
  const dispatch = useDispatch();

  const fileLoading = useSelector(selectFileLoading);
  const customerId = useSelector(selectCustomerId);
  const orderId = useSelector(selectActiveOrderId);
  const loading = useSelector(selectLoading);
  // const initialLoading = useSelector(selectInitialLoading);
  const isUpdateRequired = useSelector(selectIsUpdateRequired);
  const isOrderLoaded = useSelector(selectActiveOrderLoading);
  const isOrdersLoaded = useSelector(selectOrdersLoading);
  const isMembershipBlocked = useSelector(selectMembershipBlocked);

  useEffect(() => {
    dispatch(getProcessingStatuses({ category: "INC" }));
    dispatch(getProcessingErrors({ category: "INC" }));
    dispatch(getStates({ category: "INC" }));
  }, [dispatch]);

  useEffect(() => {
    customerId && dispatch(getOrders({ customerId }));
  }, [dispatch, customerId]);

  useEffect(() => {
    customerId && orderId && dispatch(getOrder({ customerId, orderId }));
  }, [dispatch, customerId, orderId]);

  useEffect(() => {
    if (isOrderLoaded && isOrdersLoaded) {
      dispatch(checkRequiredProducts());
    } else if (isOrdersLoaded && !isOrderLoaded && !loading) {
      dispatch(checkRequiredProducts());
    }
  }, [dispatch, isOrderLoaded, isOrdersLoaded, loading]);

  const user = useSelector(selectDetails);
  const [showSidebar, setShowSidebar] = useState(false);
  const sidebarObj = { show: showSidebar, setShow: setShowSidebar };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      sidebarObj.setShow(false);
    },
    onSwipedRight: (data) => {
      if (data.initial[0] < 20) sidebarObj.setShow(true);
    },
  });

  switch (isUpdateRequired) {
    case UPDATE_REQUIRED_CODES.refresh:
      return (
        <UpdateRequiredModal
          header="Update Required"
          message="Your order details may have changed and need to be updated. Please, reload the page."
        />
      );

    case UPDATE_REQUIRED_CODES.incBusinessLicense:
      return (
        <UpdateRequiredModal
          payment
          message="Soon, the table will contain entries about the necessary Licenses and Permits."
        />
      );

    case UPDATE_REQUIRED_CODES.incRegisterTrademark:
      return <UpdateRequiredModal payment message="" />;

    default:
      return isOrdersLoaded ? (
        <Container {...swipeHandlers}>
          <Sidebar sidebarObj={sidebarObj} navitems={navitems} footeritems={footeritems} />
          <ContentContainer>
            <Header user={user} sidebarObj={sidebarObj} handler={handler} alertscount={3} />
            <MainContainer>
              {!loading && (isOrdersLoaded || isOrderLoaded) ? <Outlet {...props} /> : <SpinnerWrapper />}
            </MainContainer>
          </ContentContainer>
          {Boolean(fileLoading) && (
            <FileLoading>
              <Spinner size="25px" />
              &nbsp;{`${fileLoading} file(s) downloading...`}
            </FileLoading>
          )}

          <RoundMenu />
          {isMembershipBlocked && <MembershipBlockedAlert />}
        </Container>
      ) : (
        <SpinnerWrapper />
      );
  }
};

PageLayout.propTypes = {
  user: PropTypes.object,
  sidebarObj: PropTypes.shape({
    show: PropTypes.bool,
    setShow: PropTypes.func,
  }),
  handler: PropTypes.func,
};

export default PageLayout;
